<template>
	<div id="paike_index">
		<index>
			<el-breadcrumb>
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">课程管理</a></el-breadcrumb-item>
				<!-- <el-breadcrumb-item><a style="color: #000000;">行政班排课</a></el-breadcrumb-item> -->
			</el-breadcrumb>
			<div class="content">
				<!-- <div class="nav-tab-bar-box u-f-item u-f-jsb">
					<div class="nav-tab-bar u-f-item">
						<div
							class="font_14 nav-tab-item"
							@click="twacherTap(index)"
							:class="index == teacherIndex ? 'active' : ''"
							v-for="(item, index) in teacherMeun"
							:key="index"
						>
							{{ item.name }}
						</div>
					</div>
				</div> -->
				<template v-if="teacherIndex == 0">
					<div class="shaixuan u-f-item">
						<!-- <el-select v-model="schoolId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="schoolChange">
							<el-option v-for="item in schooleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select> -->
						<el-select v-model="yearId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="yearChange">
							<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="xueqiId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="xqChange">
							<el-option v-for="item in xueqiList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>

					<div class="table-box">
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<div class="font_blod">共{{ total }}条数据</div>
							<div class="u-f-item">
								<!-- <el-input size="small" v-model="keyword1" placeholder="请输入关键字搜索" style="margin-right: 10px;"></el-input> -->
								<el-button size="small" @click="add">新增排课</el-button>
								<!-- <el-button size="small" @click="mergeShow = true">合并课表</el-button>
								<el-button size="small" @click="statisticsShow = true">统计工作量</el-button>
								<el-button size="small">批量录入</el-button>
								<el-button size="small" type="primary">导出</el-button>
								<el-button size="small" icon="el-icon-s-operation">排序</el-button> -->
							</div>
						</div>
						<el-table :data="CourseIndexList" style="width: 100%;border: 1px solid #EEEEEE;" height="85%">
							<el-table-column type="index" label="序号" width="120"></el-table-column>
							<el-table-column prop="name" label="名称"></el-table-column>
							<el-table-column prop="grade_name" label="关联年级"></el-table-column>
							<!-- <el-table-column prop="courseadminirule.name" label="关联规则"></el-table-column> -->
							<el-table-column prop="time" label="时间"></el-table-column>
							<el-table-column prop="status" label="状态">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.status" active-value="2" inactive-value="1" active-color="#1890FF" inactive-color="#DCDFE6" @change="changeStatus(scope.row.status, scope.row.id)"></el-switch>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">
									<el-button @click="editor(scope.row)" type="text" size="small">编辑</el-button>
									<span style="color: #E7E7E7;">|</span>
									<el-button @click="deploy(scope.row)" type="text" size="small">配置</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right" style="margin-top: 10px;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
						<!-- 编辑 -->
						<el-dialog title="编辑排课" :visible.sync="editorShow" width="30%" :before-close="handleClose">
							<div class="editorBox">
								<div class="editorBox">
									<div class="u-f-item div">
										<div class="input-lebal u-f1" style="text-align: right;">
											排课名称
											<span>*</span>
										</div>
										<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="coursename" maxlength="10" show-word-limit></el-input></div>
									</div>
									<div class="u-f-item div">
										<div class="input-lebal u-f1" style="text-align: right;">
											关联年级
											<span>*</span>
										</div>
										<div class="u-f4">
											<el-select v-model="addgradetext" placeholder="请选择" style="width: 100%;" @change="changeNianJi">
												<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</div>
									<!-- <div class="u-f-item div" v-if="addgradetext">
										<div class="input-lebal u-f1" style="text-align: right;">
											关联岗位表
											<span>*</span>
										</div>
										<div class="u-f4">
											<el-select v-model="jobId" placeholder="请选择" style="width: 100%;">
												<el-option v-for="item in jobList" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</div>
									<div class="u-f-item div" v-if="addgradetext">
										<div class="input-lebal u-f1" style="text-align: right;">
											关联规则
											<span>*</span>
										</div>
										<div class="u-f4">
											<el-select v-model="guizeId" placeholder="请选择" style="width: 100%;">
												<el-option v-for="item in CourseRuleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</div> -->
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="closedialog">取消</el-button>
								<el-button type="primary" @click="editorSubmit">确 定</el-button>
							</span>
						</el-dialog>
						<!-- 新增 -->
						<el-dialog title="新增排课" :visible.sync="addShow" width="30%" :before-close="handleClose">
							<div class="editorBox">
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										排课名称
										<span>*</span>
									</div>
									<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="coursename" maxlength="10" show-word-limit></el-input></div>
								</div>
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										关联年级
										<span>*</span>
									</div>
									<div class="u-f4">
										<el-select v-model="addgradetext" placeholder="请选择" style="width: 100%;" @change="changeNianJi">
											<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
								</div>
								<!-- <div class="u-f-item div" v-if="addgradetext">
									<div class="input-lebal u-f1" style="text-align: right;">
										关联岗位表
										<span>*</span>
									</div>
									<div class="u-f4">
										<el-select v-model="jobId" placeholder="请选择" style="width: 100%;">
											<el-option v-for="item in jobList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
								</div>
								<div class="u-f-item div" v-if="addgradetext">
									<div class="input-lebal u-f1" style="text-align: right;">
										关联规则
										<span>*</span>
									</div>
									<div class="u-f4">
										<el-select v-model="guizeId" placeholder="请选择" style="width: 100%;">
											<el-option v-for="item in CourseRuleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
								</div> -->
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="closedialog()">取 消</el-button>
								<el-button type="primary" @click="submitadd">确 定</el-button>
							</span>
						</el-dialog>
						<!-- 合并课表 -->
						<!-- <el-dialog title="合并课表" :visible.sync="mergeShow" width="35%">
							<div class="mergeBox">
								<div class="u-f-item u-f-jsb">
									<el-select v-model="value" placeholder="请选择" size="small">
										<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
									<el-button size="small" @click="addMergeShow = true">新建合并</el-button>
								</div>
								<div class="table_box">
									<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
										<el-table-column type="selection" width="55"></el-table-column>
										<el-table-column label="名称"></el-table-column>
										<el-table-column prop="name" label="关联年级"></el-table-column>
										<el-table-column prop="address" label="当前使用"></el-table-column>
									</el-table>
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button @click="mergeShow = false">重置</el-button>
								<el-button type="primary" @click="mergeShow = false">确 定</el-button>
							</span>

							<el-dialog title="新建合并" :visible.sync="addMergeShow" width="30%" append-to-body>
								<div class="editorBox" style="padding: 0 20px;">
									<div class="u-f-item div">
										<div class="input-lebal u-f1 font_blod" style="text-align: right;padding-right: 10px;">
											排课名称
											<span style="color: red;">*</span>
										</div>
										<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="addname" maxlength="10" show-word-limit></el-input></div>
									</div>
								</div>
								<span slot="footer" class="dialog-footer">
									<el-button type="primary" plain @click="addMergeShow = false">取 消</el-button>
									<el-button type="primary" @click="addMergeShow = false">确 定</el-button>
								</span>
							</el-dialog>
						</el-dialog> -->
						<!-- 统计工作量 -->
						<!-- <el-dialog title="统计工作量" :visible.sync="statisticsShow" width="45%">
							<div class="statisticsBox">
								<div class="u-f-item u-f-jsb">
									<div class="font_blod">共20名教师</div>
									<div>
										<el-input placeholder="请输入姓名搜索" v-model="input4" size="small"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
									</div>
								</div>
								<div style="border-top: 1px solid #EEEEEE;margin-top: 15px;">
									<el-table>
										<el-table-column label="姓名"></el-table-column>
										<el-table-column label="任教学科"></el-table-column>
										<el-table-column label="任教年级"></el-table-column>
										<el-table-column label="岗位课时"></el-table-column>
										<el-table-column label="当前课时"></el-table-column>
										<el-table-column label="操作"></el-table-column>
									</el-table>
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="statisticsShow = false">取 消</el-button>
								<el-button type="primary" @click="statisticsShow = false">确 定</el-button>
							</span>
						</el-dialog> -->
					</div>
				</template>
				<!-- 行政班排课规则 -->
				<template v-if="teacherIndex == 1">
					<div class="shaixuan u-f-item">
						<el-select v-model="schoolId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="schoolChange">
							<el-option v-for="item in schooleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="yearId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="yearChange">
							<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="xueqiId" size="small" style="width: 7.29vw;margin-right: 0.52vw;">
							<el-option v-for="item in xueqiList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
						<el-select v-model="gradeId" size="small" style="width: 7.29vw;margin-right: 0.52vw;" @change="gradeChange">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>

					<div class="table-box" v-if="gradeId">
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<div class="font_blod">共{{total1}}条数据</div>
							<div class="u-f-item">
								<el-input size="small" v-model="keyword" @input="shousuo" placeholder="请输入名称搜索" style="margin-right: 10px;"></el-input>
								<!-- <el-button size="small" @click="CopyShow = true">拷贝规则</el-button> -->
								<el-button size="small" @click="addruleShow = true">新增规则</el-button>
							</div>
						</div>
						<el-table :data="CourseRuleList" style="width: 100%">
							<el-table-column type="index" width="120" label="序号"></el-table-column>
							<el-table-column prop="name" label="名称"></el-table-column>
							<el-table-column prop="grade" label="上课天数">
								<template slot-scope="scope">
									<span v-for="(item, i) in scope.row.attend_day" :key="i">
										{{ item }}
										<span v-if="scope.row.attend_day.length > i + 1" style="color: #ccc;margin: 0 5px;">|</span>
									</span>
								</template>
							</el-table-column>
							<el-table-column prop="curriculum_time_name" label="关联时段"></el-table-column>
							<el-table-column prop="type" label="状态">
								<template slot-scope="scope">
									<span style="color: #C9C9C9;" v-if="scope.row.is_setting == 1">配置中</span>
									<span style="color: #C9C9C9;" v-if="scope.row.is_setting == 2">配置完成</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">
									<el-button @click="editoreditrule(scope.row)" type="text" size="small">编辑</el-button>
									<span style="color: #E7E7E7;">|</span>
									<el-button @click="guize(scope.row)" type="text" size="small">配置</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="currentPage1"
								:page-size="10"
								layout="total, prev, pager, next"
								:total="total1"
							></el-pagination>
						</div>

						<!-- 拷贝规则 -->
						<el-dialog title="拷贝规则" :visible.sync="CopyShow" width="35%" :before-close="handleClose">
							<div class="editorBox">
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										规则名称
										<span>*</span>
									</div>
									<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="name" maxlength="10" show-word-limit></el-input></div>
								</div>
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										选择规则
										<span>*</span>
									</div>
									<div class="u-f4">
										<el-select v-model="gradetext" placeholder="请选择" style="width: 100%;">
											<el-option v-for="item in CourseRuleList" :key="item.value" :label="item.label" :value="item.value"></el-option>
										</el-select>
									</div>
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="CopyShow = false">取消</el-button>
								<el-button type="primary" @click="CopyShow = false">确 定</el-button>
							</span>
						</el-dialog>
						<!-- 新增 -->
						<el-dialog title="新增规则" :visible.sync="addruleShow" width="30%" :before-close="handleClose">
							<div class="editorBox">
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										名称
										<span>*</span>
									</div>
									<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="addname" maxlength="10" show-word-limit></el-input></div>
								</div>
								<div class="u-f div">
									<div class="input-lebal u-f1" style="text-align: right;">
										上课天数
										<span>*</span>
									</div>
									<div class="u-f4">
										<div class="weeks">
											<div :class="item.checked?'active':''" v-for="(item, index) in week" :key="index" @click="weeksChange(index)">{{ item.name }}</div>
										</div>
									</div>
								</div>
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										上课时段
										<span>*</span>
									</div>
									<div class="u-f4">
										<el-select v-model="timeId" placeholder="请选择" style="width: 100%;">
											<el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="closedialog">取 消</el-button>
								<el-button type="primary" @click="submitaddrule">确 定</el-button>
							</span>
						</el-dialog>
						
						<!-- 新增 -->
						<el-dialog title="编辑规则" :visible.sync="editruleShow" width="30%" :before-close="handleClose">
							<div class="editorBox">
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										名称
										<span>*</span>
									</div>
									<div class="u-f4"><el-input type="text" placeholder="请输入内容" v-model="addname" maxlength="10" show-word-limit></el-input></div>
								</div>
								<div class="u-f div">
									<div class="input-lebal u-f1" style="text-align: right;">
										上课天数
										<span>*</span>
									</div>
									<div class="u-f4">
										<div class="weeks">
											<div :class="item.checked?'active':''" v-for="(item, index) in week" :key="index" @click="weeksChange(index)">{{ item.name }}</div>
										</div>
									</div>
								</div>
								<div class="u-f-item div">
									<div class="input-lebal u-f1" style="text-align: right;">
										上课时段
										<span>*</span>
									</div>
									<div class="u-f4">
										<el-select v-model="timeId" placeholder="请选择" style="width: 100%;">
											<el-option v-for="item in timeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
								</div>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="primary" plain @click="closedialog">取 消</el-button>
								<el-button type="primary" @click="submiteditrule">确 定</el-button>
							</span>
						</el-dialog>
					</div>
					<div v-else class="table-box u-f-justify u-f-column">
						<img src="../../assets/image/nothing.png" style="width: 120px;">
						<div style="color: #A4A4A4;">暂无数据</div>
					</div>
				</template>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			tableData:[],
			keyword: '',
			editorShow: false,
			addShow: false,
			mergeShow: false,
			addMergeShow: false,
			statisticsShow: false,
			currentPage1: 10,
			teacherMeun: [{ name: '行政班排课' }, { name: '行政班排课规则' }],
			teacherIndex: 0,
			name: '',
			gradetext: '',
			station: '',
			stationList: [],
			rule: '',
			ruleList: [],
			addname: '',
			addgradetext: '',
			grade: '高二',
			CopyShow: false,
			addruleShow: false,
			editruleShow:false,
			week: [{name:'星期一',checked:false},{name:'星期二',checked:false},{name:'星期三',checked:false},{name:'星期四',checked:false},{name:'星期五',checked:false},{name:'星期六',checked:false},{name:'星期天',checked:false}],
			CourseIndexList: [],
			page: 1,
			limit: 10,
			total: 0,
			CourseRuleList: [],
			keyword1: '',
			total1:0,
			attendDays:[],
			timeList:[],
			timeId:'',
			schoolId:'',
			schooleList:[],
			yearId:'',
			yearList:[],
			xueqiList:[],
			xueqiId:'',
			gradeList:[],
			gradeId:'',
			CourseRuleId:'',
			coursename:'',
			guizeId:'',
			jobList:[],
			jobId:'',
			courseId:''
		};
	},
	mounted() {
		this.getCourseRuleIndex();
		this.courseTimeList()
		this.schoolCampusIndex()
		this.getSemesterList()
	},
	methods: {
		changeNianJi(){
			this.gradeId = this.addgradetext;
			this.limit = 100;
			this.getCourseRuleIndex()
			this.getJobList()
		},
		yearChange(){
			this.xueqiId = '';
			this.getXueqiList()
		},
		handleClose(done){
			this.load()
			done()
		},
		closedialog(){
			this.load();
			this.addruleShow = false;
			this.editruleShow = false;
			this.addShow = false;
			this.editorShow = false;
		},
		// 数据重置
		load(){
			this.addname = '';
			this.timeId = '';
			this.coursename = '';
			this.guizeId = '';
			this.addgradetext = '';
			this.jobId = '';
			this.courseId = '';
			this.attendDays = [];
			for(let i in this.week){
				this.week[i].checked = false;
			}
		},
		// 新增排课
		submitadd(){
			if(this.coursename==''||!this.coursename){
				return this.$message.error('请输入名称')
			}
			if(!this.addgradetext||this.addgradetext==''){
				return this.$message.error('请选择年级')
			}
			// if(this.guizeId==''||!this.guizeId){
			// 	return this.$message.error('请选择关联规则')
			// }
			// if(this.jobId==''||!this.jobId){
			// 	return this.$message.error('请选择关联岗位表')
			// }
			let data = {
				name:this.coursename,
				// school_campus_id:this.schoolId,
				year_id:this.yearId,
				semester_id:this.xueqiId,
				grade_id:this.addgradetext,
				// course_admini_rule_id:this.guizeId,
				// job_id:this.jobId
			}
			this.$api.paike.addCourse(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.load()
					this.page = 1;
					this.getCourseindex();
					this.addShow = false;
				}else{
					this.$message.success(res.data.msg)
				}
			})
		},
		// 编辑排课
		editorSubmit(){
			
			if(this.coursename==''||!this.coursename){
				return this.$message.error('请输入名称')
			}
			if(!this.addgradetext||this.addgradetext==''){
				return this.$message.error('请选择年级')
			}
			// if(this.guizeId==''||!this.guizeId){
			// 	return this.$message.error('请选择关联规则')
			// }
			// if(this.jobId==''||!this.jobId){
			// 	return this.$message.error('请选择关联岗位表')
			// }
			let data = {
				name:this.coursename,
				// school_campus_id:this.schoolId,
				year_id:this.yearId,
				semester_id:this.xueqiId,
				grade_id:this.addgradetext,
				// course_admini_rule_id:this.guizeId,
				// job_id:this.jobId,
				id:this.courseId
			}
			this.$api.paike.editCourse(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功')
					this.load()
					this.page = 1;
					this.getCourseindex();
					this.editorShow = false;
				}else{
					this.$message.success(res.data.msg)
				}
			})
		},
		editoreditrule(item){
			this.addname = item.name;
			this.timeId = item.curriculum_time_id;
			this.attendDays = item.attend_day;
			this.CourseRuleId = item.id;
			for(let i in this.week){
				for(let j in this.attendDays){
					if(this.week[i].name == this.attendDays[j]){
						this.week[i].checked = true;
					}
				}
			}
			this.editruleShow = true;
		},
		// 编辑排课规则
		submiteditrule(){
			if(this.addname==''||!this.addname){
				return this.$message.error('请输入名称')
			}
			if(!this.attendDays.length){
				return this.$message.error('请选择上课天数')
			}
			if(this.timeId==''||!this.timeId){
				return this.$message.error('请选择时段')
			}
			let data = {
				name:this.addname,
				year_id:this.yearId,
				semester_id:this.xueqiId,
				grade_id:this.gradeId,
				curriculum_time_id:this.timeId,
				attend_day:this.attendDays,
				// school_campus_id:this.schoolId,
				id:this.CourseRuleId
			}
			this.$api.paike.editCourseRule(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功')
					this.load()
					this.getCourseRuleIndex();
					this.editruleShow = false;
				}else{
					this.$message.success(res.data.msg)
				}
			})
		},
		
		// 新增排课规则
		submitaddrule(){
			if(this.addname==''||!this.addname){
				return this.$message.error('请输入名称')
			}
			if(!this.attendDays.length){
				return this.$message.error('请选择上课天数')
			}
			if(this.timeId==''||!this.timeId){
				return this.$message.error('请选择时段')
			}
			let data = {
				name:this.addname,
				year_id:this.yearId,
				semester_id:this.xueqiId,
				grade_id:this.gradeId,
				curriculum_time_id:this.timeId,
				attend_day:this.attendDays,
				// school_campus_id:this.schoolId
			}
			this.$api.paike.addCourseRule(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.load()
					this.getCourseRuleIndex();
					this.addruleShow = false;
				}else{
					this.$message.success(res.data.msg)
				}
			})
		},
		// 修改排课状态
		changeStatus(status, id){
			let data = {
				id: id,
				status: status
			}
			this.$api.paike.statusCourse(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('修改成功')
					this.getCourseindex();
				}else{
					this.$message.success(res.data.msg)
				}
			})
		},
		// 校区列表
		schoolCampusIndex() {
			this.$api.setting.schoolCampusIndex().then(res => {
				if (res.data.code == 1) {
					this.schooleList = res.data.data.rows;
					this.schoolId = this.schooleList[0].id;
					this.getSemesterList()
				}
			});
		},
		// 岗位列表
		getJobList(){
			let data = {
				'grade_id': this.addgradetext
			}
			if(this.keyword){data.name = this.keyword}
			this.$api.station.getJobList({filter: JSON.stringify(data)}).then(res=>{
				if(res.data.code==1){
					this.jobList = res.data.data.rows;
				}
			})
		},
		// 获取学年列表
		getSemesterList() {
			this.$api.setting.getSemesterList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					for (let i in data) {
						if (data[i].is_default == 2) {
							this.yearId = data[i].id;
						}
					}
					this.yearList = data;
					this.gradeData()
					this.getXueqiList();
				}
			});
		},
		getXueqiList() {
			this.$api.setting
				.getXueQiList({
					year_id:this.yearId
				})
				.then(res => {
					if (res.data.code == 1) {
						this.xueqiList = res.data.data;
						for(let i in this.xueqiList){
							if(this.xueqiList[i].is_default==2){
								this.xueqiId = this.xueqiList[i].id
							}
						}
						this.getCourseindex()
					}
				});
		},
		xqChange(e){
			this.xueqiId = e;
			this.getCourseindex()
		},
		// 年级列表
		gradeData(){
			console.log(this.schoolId)
			this.$api.setting.getGradeList({filter: JSON.stringify({'year_id': this.yearId})}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
				}
			})
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getCourseindex();
		},
		twacherTap(index) {
			this.page = 1;
			this.gradeId = '';
			this.teacherIndex = index;
		},
		// 编辑
		editor(row) {
			this.coursename = row.name;
			this.addgradetext = row.grade_id;
			this.guizeId = row.course_admini_rule_id;
			this.jobId = row.job_id;
			this.courseId = row.id;
			this.gradeId = this.addgradetext;
			this.limit = 100;
			this.getCourseRuleIndex()
			this.getJobList()
			this.editorShow = true;
		},
		// 配置
		deploy(row) {
			this.$router.push({ path: '/deploy', query: { id: row.id,title:row.name} });
		},
		// 新增
		add() {
			this.addShow = true;
		},
		// 获取行政班排课列表
		getCourseindex() {
			let data = {
				// school_campus_id: this.schoolId,
				year_id: this.yearId,
				semester_id: this.xueqiId
			};
			this.$api.paike.getCourseindex({page: this.page,limit: this.limit,filter: JSON.stringify(data)}).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					for (let i in list) {
						list[i].time = this.$common.timeToDate(list[i].createtime);
					}
					this.total = res.data.data.total;
					this.CourseIndexList = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		schoolChange(e){
			window.localStorage.setItem("campusid",e);
			this.gradeId = '';
			this.getSemesterList()
			this.gradeData()
		},
		// 获取排课规则列表
		getCourseRuleIndex() {
			let data = {
				page: this.page,
				limit: this.limit,
				filter:{
					grade_id:this.gradeId
				}
			};
			// if(this.gradeid){data.filter.grade_id = this.gradeid}
			if (this.keyword) {
				data.filter.name = this.keyword;
			}
			this.$api.paike.getCourseRuleIndex(data).then(res => {
				if (res.data.code == 1) {
					this.CourseRuleList = res.data.data.rows;
					this.total1 = res.data.data.total;
				}
			});
		},
		// 年级切换
		gradeChange() {
			this.getCourseRuleIndex();
		},
		// 搜索规则
		shousuo(){
			console.log(this.keyword)
			this.getCourseRuleIndex();
		},
		// 选择日期
		weeksChange(index){
			if(this.week[index].checked){
				this.week[index].checked = false;
			}else{
				this.week[index].checked = true;
			}
			let arr = [];
			for(let i in this.week){
				if(this.week[i].checked){
					arr.push(this.week[i].name)
				}
			}
			this.attendDays = arr;
			console.log(this.attendDays)
		},
		// 获取上课时段
		courseTimeList(){
			this.$api.setting.courseTimeList({}).then(res=>{
				if(res.data.code==1){
					this.timeList = res.data.data.rows.list;
				}
			})
		},
		guize(row){
			this.$router.push({
			    path:"/rule",
			    query:{
			          id:row.id,
					  title:row.name
			    }
			})
		}
	}
};
</script>

<style lang="scss">
#paike_index {
	.content {
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 0.62vw;
			margin-top: 1.04vw;
			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				div:last-child {
					border-right: none;
				}
				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}
				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.7vw;
				}
			}
		}
		.shaixuan {
			background-color: #ffffff;
			padding: 10px 30px;
			margin-top: 10px;
		}
		.table-box {
			background-color: #ffffff;
			padding: 0 20px;
			margin-top: 20px;
			height: 78vh;
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 10px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-dialog__header {
				padding: 15px;
				font-size: 14px;
				border-bottom: 1px solid #eeeeee;
				.el-dialog__title {
					font-size: 16px;
				}
			}
			.editorBox {
				padding: 0 20px;
				.div {
					padding: 10px 0 20px 0;
					.input-lebal {
						font-weight: bold;
						padding-right: 10px;
						span {
							color: red;
							font-weight: 100;
							margin-left: 3px;
						}
					}
				}
				.weeks {
					display: flex;
					flex-wrap: wrap;
					div {
						background-color: #f3f3f3;
						padding: 6px 13px;
						margin-right: 10px;
						border-radius: 5px;
						margin-bottom: 10px;
						cursor: pointer;
					}
					div:nth-child(3n) {
						margin-right: 0;
					}
					.active{
						color: #409EFF;
					}
				}
			}
			.mergeBox {
				.table_box {
					margin-top: 15px;
					border-top: 1px solid #eeeeee;
					height: 50vh;
					overflow-y: scroll;
				}
			}
			.el-dialog__body {
				padding: 15px 20px;
			}
		}
	}
}
</style>
